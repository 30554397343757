@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

.navBarContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 spacing(8);
    background-color: $backgroundColor;
    z-index: 1000;


    &>.linzeLink {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: fit-content;

        & .logo {
            height: 31px;
        }
    }

    & .menu {
        display: none;

        & .icon {
            font-size: $largeFontSize;
            color: #7100ff;
        }
    }

    & .sell {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: spacing(1) spacing(3);
        background: rgba(255, 255, 255, 0);
        border-radius: $borderRadius;
        cursor: pointer;

        &:hover {
            background: $secondBackgroundColor;
        }

        &:focus {
            background: $secondBackgroundColor;
        }

        & p {
            font-size: $smallFontSize;
            font-weight: 500;
            color: $secondFontColor;
            margin: 0;
        }
    }

    & .notifications {
        position: relative;

        & .dropdown {
            padding: spacing(5) spacing(3);
            right: -10px;
            min-height: 100px;
            max-height: 500px;
        }

        & .unReaded {
            position: absolute;
            top: 2px;
            right: 6px;

            & .badge {
                background: $errorColor;
            }
        }
    }

    & .profile {
        display: flex;
        align-items: center;
        justify-content: center;

        & .picture {
            display: flex;
            align-items: center;
            cursor: pointer;

            &.open {
                & .icon {
                    transform: rotate(180deg);
                }
            }

            & .icon {
                width: 32px;
                color: $mainFontColor;
            }

            & .image {
                width: 28px;
                height: 28px;
                border: 2px solid $thirdBackgroundColor;
                border-radius: 100px;
                object-fit: cover;
            }
        }

        & .dropdown {
            right: -20px;
            padding: 0 !important;

            & .content {
                display: flex;
                flex-direction: column;

                & .tab {
                    padding: spacing(2) spacing(2);
                    cursor: pointer;

                    &:not(:last-child) {
                        border-bottom: 1px solid $secondBackgroundColor;
                    }

                    &:first-child,
                    &:last-child {
                        border-radius: 0.5rem;
                    }

                    &:hover {
                        background: $fiveBackgroundColor;

                        & p {
                            text-shadow: 0 0 0.5px $fontColor, 0 0 0.5px $fontColor
                        }
                    }

                    &:active {
                        background: $secondBackgroundColor;
                    }

                    &.blue {
                        & p {
                            color: $mainFontColor;
                            font-weight: 500;
                        }
                    }

                    & p {
                        font-size: $smallFontSize;
                        font-weight: 400;
                        color: $fontColor;
                        margin: 0;
                    }
                }
            }
        }
    }

    &>.navBarLabels {
        display: flex;
        align-items: center;
        gap: spacing(4) spacing(5);
        flex: 1;
        justify-content: flex-end;
        list-style: none;

        & .border {
            border: 0.1rem solid $secondBackgroundColor !important;
            padding: 10px 64px 10px 64px;
            border-radius: 24px;
        }

        & .link {
            display: flex;
            align-items: center;
            font-size: $smallFontSize;
            color: $mainFontColor;
            font-weight: 400;
            margin: 0;
            position: relative;
            &:hover {
                text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor;
            }
        }

        & .whiteLink {
            display: flex;
            align-items: center;
            font-size: $smallFontSize;
            color: $secondFontColor;
            font-weight: 400;
            margin: 0;

            &:hover {
                text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor;
            }
        }


        & .activeLink {
            text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor !important;
        }

        & .notificationsIcon {
            font-size: $mediumLargeFontSize;
            color: $mainFontColor;
        }
    }

    & .bkgr {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        visibility: hidden;
        opacity: 0;
        background: rgba($color: #000000, $alpha: 0.3);
        z-index: 10;
    }
}

.transparent {
    background-color: rgba(255, 0, 0, 0);
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
}

.whiteColor {
    color: #ffffff !important;
}

.modalTop{
    top: 110px;
}
.currentRoutePoint{
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: -12px;
    width: 8px;
    margin: 0 auto;
    height: 8px;
    border-radius: 8px;
    background-color: #7100ff;
}


@media screen and (max-width: 1024px) {
    .navBarContainer {
        padding: 0 spacing(3.25);

        &>.linzeLink {
            & .logo {
                height: 24px;
            }
        }

        & .menu {
            display: flex;
        }

        &>.navBarLabels {
            position: fixed;
            top: 0;
            right: -100%;
            bottom: 0;
            flex-direction: column-reverse;
            align-items: start;
            justify-content: start;
            padding: spacing(1) spacing(3);
            gap: 0;
            width: 200px;
            background: $backgroundColor;
            z-index: 11;
            transition: 0.3s ease-in-out;

            & .border {
                border: 0.0rem solid $secondBackgroundColor !important;
                padding: 0;
            }

            &.open {
                right: 0;
                transition: 0.3s ease-in-out;
            }

            & .whiteLink {
                align-items: start;
                padding: spacing(2.5) 0;
                width: 100%;
                font-size: $extraSmallFontSize;
                color: #7100ff;
                font-weight: 500;
            }

            & .liLink {
                width: 100%;
            }

            & .link {
                align-items: start;
                padding: spacing(2.5) 0;
                width: 100%;
                font-size: $extraSmallFontSize;
                color: #7100ff;
                font-weight: 500;
                flex-direction: row;
                display: flex;
            }

            & .link:hover {
                font-weight: bold;
                color: #7300ffcb;
                text-decoration: underline;
            }
        }

        & .bkgr {
            &.open {
                visibility: visible;
                opacity: 1;
                transition: 0.3s ease-in-out;
            }
        }

        & .activeLink {
            text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor !important;
        }
    }
    .currentRoutePoint{
        position: relative;
        align-self: center;
        width: 8px;
        margin: unset;
        bottom: unset;
        top: unset;
        height: 8px;
        margin-left: 8px;
        border-radius: 8px;
        background-color: #7100ff;
    }
}
.disabledDropDown{
    display: none;
}
@import "../../styles/variables.scss";
@import "../../styles/functions.scss";

footer {
    display: flex;
    flex-direction: column;
    background: $thirdBackgroundColor;
    & .footerNav{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: spacing(10) spacing(8);
        padding: spacing(8) spacing(8) spacing(9);
        & .item{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: spacing(2);
            & ul{
                display: flex;
                flex-direction: column;
                align-items: start;
                gap: spacing(2);
                list-style: none;
                margin: 0;
                padding: 0;
            }
            & .title,
            & .link{
                font-size: $normalFontSize;
                color: $secondFontColor;
                margin: 0;
                &.active{
                    text-decoration: underline;
                    text-decoration-thickness: 1px;
                    text-underline-offset: 2.5px;
                    text-decoration-color: $thirdFontColor;
                }
            }
            & .title{
                font-weight: 400;
            }
            & .link{
                font-weight: 200;
                &:hover{
                    text-shadow: 0 0 1px $secondFontColor, 0 0 1px $secondFontColor;
                }
            }
        }
    }
    & .footerContent{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: spacing(2.5) spacing(8);
        background: $backgroundColor;
        & > .leftFooter{
            & > p{
                color: #000000;
                width: max-content;
                font-size: $smallFontSize;
            }
        }
        & .rightFooter{
            display: flex;
            align-items: flex-end;
            gap: spacing(2);
            flex-direction: column;
            & > .rowFooter{
                display: flex;
                flex-direction: row;
                gap: spacing(4);
                font-size: $smallFontSize;
                color: $fontColor;
                font-weight: 400;
                align-items: center;
                margin: 0;
                & > .aLink:hover{
                    cursor: pointer;
                }
                & > .aLink{
                    color: #000000;
                    width: max-content;
                }
                & > .socialNetworksContainer{
                    display: flex;
                    flex-direction: row;
                    gap: spacing(2);
                    & > img:hover{
                        cursor: pointer;
                    }
                }
                & > p{
                    width: max-content;
                    & > span{
                        text-decoration: underline;
                        font-weight: 500;
                    }
                    & > span:hover{
                        cursor: pointer;
                    }
                }
            }
            & p{
                font-size: $smallFontSize;
                color: $fontColor;
                font-weight: 400;
                margin: 0;
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .footerContainer{
        margin: spacing(0.5) 0 0;
        & .footerNav{
            flex-direction: column;
            justify-content: start;
            flex-wrap: nowrap;
            margin: 0;
            padding: spacing(4) spacing(3);
            gap: spacing(3);
            width: auto;
            & .item{
                & .title{
                    display: none;
                }
                & .link{
                    font-size: $smallFontSize;
                }
            }
        }
        & .footerContent{
            padding: spacing(2) spacing(3.25);
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            & .rightFooter{
                align-items: flex-start;
                & p{
                    font-size: $extraSmallFontSize;
                }
                & > .rowFooter{
                    gap: spacing(2);
                    flex-wrap: wrap;
                }
            }
        }
    }
}